.header {
    display: flex;
    justify-content: space-between;
    height: 50px;
    width: 100%;
}

.headerItem {
    width:50%;
}

.nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: turret-road;
    font-size: 1.75em;
    margin-right: 8px;
}

.navItem {
    padding: 4px 10px;
    transition: all 0.3s ease;
    color: var(--studios-text-color);
    text-decoration: none;
}

.navItem:hover {
    transform: scale(1.1);
}

.logo {
    margin: 10px;
    width: 90%;
    max-width: 560px;
    justify-self: flex-start;
}