:root {
    --studios-text-color: rgba(189,189,189,1);
    --studios-background-color: rgba(35,34,36,1);
    --studios-button-text-color: rgba(227,227,227,1);
    --studios-button-background-color: rgba(157,15,179,1);
}

@font-face {
    font-family: kumbhsans;
    src: url(./../fonts/KumbhSans-Regular.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: kumbhsans;
    src: url(./../fonts/KumbhSans-Medium.ttf) format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: rajdhani;
    src: url(./../fonts/Rajdhani-Bold.ttf) format('truetype');
}

@font-face {
    font-family: saira;
    src: url(./../fonts/SairaSemiCondensed-ExtraLight.ttf) format('truetype');
}

@font-face {
    font-family: timeburner;
    src: url(./../fonts/timeburnernormal.ttf) format('truetype');
}

@font-face {
    font-family: turret-road;
    src: url(./../fonts/TurretRoad-ExtraLight.ttf) format('truetype');
}

* {
    box-sizing: border-box;
}

html, body, body > div {
    height: 100%;
    margin: 0;
    font-family: kumbhsans;
    background-color: var(--studios-background-color);
    color: var(--studios-text-color);
}

.studio {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

button, .button, .button:visited {
    font-family: kumbhsans;
    font-size: 1.1em;
    color: var(--studios-button-text-color);
    background-color: var(--studios-button-background-color);
    border: none;
    border-radius: 2px;
    cursor: pointer;
    width: 125px;
    min-width: 75px !important;
    transition: all 0.3s ease;
    padding: 4px 7px;
    text-decoration: none;
    text-align: center;
}

button:hover, .button:hover {
    transform: scale(1.1);
}

.content {
    height: 100%;
    width: 90%;
    display: flex;
}

.sideBar {
    width: 50%;
    max-width: 580px;
    height: 100%;
}

h2 {
    font-size: 1.75rem;
    font-weight: normal;
}
